import cls from 'classnames'
import { useEffect, useState, ReactNode } from 'react'

import ExclamationIcon16 from '../../base/data-display/icons/16/exclamation-icon-16'
import BodySmall from '../../base/data-display/typography/body-small/body-small'
import FormGroup from '../../base/layout/form-group/form-group'

import OtpInput, { REGEX_DIGIT } from './otp-input'
import styles from './otp.module.css'

interface OtpFormProps {
  /*
   * How many digits is this code?
   */
  codeLength: number
  /**
  String label that will render above the inputs.
  */
  label?: string
  /**
   * Function to call when the OTP input value changes.
   */
  onChange: () => void
  /**
   * Function to call when the OTP form is filled out.
   */
  onSubmit: (otp: string) => void
  /**
   * Is there an error message associated with this input?
   */
  error?: ReactNode
  /**
   * Is the OTP input field disabled?
   */
  disabled?: boolean
  /**
   * Additional class names to apply to the component.
   */
  className?: string
  /**
   * The variant of the OTP form. Default is dark.
   */
  variant?: 'light' | 'dark'
}

export const OtpForm: React.FC<OtpFormProps> = ({
  codeLength,
  disabled,
  error,
  label,
  onChange,
  onSubmit,
  className,
  variant = 'dark',
}) => {
  const [otp, setOtp] = useState('')
  const isValidCode = REGEX_DIGIT.test(otp)

  // Auto submit when we have a valid code
  useEffect(() => {
    const isCorrectLength = otp.length === codeLength

    if (isValidCode && isCorrectLength && !error) {
      onSubmit(otp)
    }
  }, [codeLength, onSubmit, otp, error, isValidCode])

  // Arbitrary number, set to max 7 for now
  const maxCodeLength = Math.min(codeLength, 7)

  return (
    <FormGroup className={cls(styles.formGroup, className)}>
      {label ? <h6>{label}</h6> : null}
      <OtpInput
        variant={variant}
        value={otp}
        valueLength={maxCodeLength}
        onChange={(otp: string) => {
          setOtp(otp)

          // Right now, this clears an error in the parent if there is one
          onChange()
        }}
        disabled={disabled}
      />
      {error ? (
        <div className="flex">
          <ExclamationIcon16 className="error-text" />
          &nbsp;
          {typeof error === 'string' ? (
            <BodySmall className="error-text" weight="bold">
              {error}
            </BodySmall>
          ) : (
            error
          )}
        </div>
      ) : null}
    </FormGroup>
  )
}

export default OtpForm
