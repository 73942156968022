import React from 'react'

import ExclamationIcon16 from '../data-display/icons/16/exclamation-icon-16'
import BodySmall from '../data-display/typography/body-small/body-small'

export const ErrorMessage = ({ errorMessage }: { errorMessage?: string }) => {
  return errorMessage ? (
    <div className="flex items-center gap-[0.5rem] mt-[0.125rem]">
      <ExclamationIcon16 className="text-[var(--text-error)]" />
      <BodySmall color="error">{errorMessage}</BodySmall>
    </div>
  ) : null
}
